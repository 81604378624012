import { useEffect, useState, useContext} from "react";
import { useNavigate } from "react-router-dom";
import { TbSearch } from "react-icons/tb";
import { CgShoppingCart } from "react-icons/cg";
import { AiOutlineHeart } from "react-icons/ai";
import Logo from "./Logo.png";
import Search from "./Search/Search";
import Cart from "../Cart/Cart";
import { Context } from "../../utils/context"; 


import "./Header.scss";
import FourDotMenu from "./FourDotMenu";
const Header = () => {
    const [scrolled, setScrolled] = useState(false);
    const [showCart, setShowCart] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const {cartCount}=useContext(Context);
    const navigate = useNavigate();
    const handleScroll = () => {
        const offset = window.scrollY
        if(offset > 200){
            setScrolled(true);
        }else{
            setScrolled(false);
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    },[]);
    return (
        <>
    <header className={`fixed-top bg-light main-header ${scrolled ? "sticky-header": " "}`}>
        <div className="header-content">
        
            <div className="left d-block" style={{overflow:"visible"}} onClick={() => navigate("/")}>
                <img style={{width:"150px",height:"50px",borderRadius:"10px",margin:"15px"}} src={Logo}/>
            </div>
            <div className="right">
            <ul style={{cursor:"pointer"}} id="pcmenu" className="d-none d-lg-block">
            <li className="nav-link text-dark pd" onClick={() => {navigate("/");setShowCart(false)}}>Home</li>
            <li className="nav-link text-dark pd" onClick={() => navigate("/about")}>About</li>
            <li className="nav-link text-dark pd" onClick={() => navigate("/services")}>Services</li>
            <li className="nav-link text-dark pd" onClick={() => navigate("/gallery")}>Gallery</li>
            <li className="nav-link text-dark pd" onClick={() => {navigate("/blog");setShowCart(false)}}>Blogs</li>
            <li className="nav-link text-dark pd" onClick={() => navigate("/contact")}>Contact</li>
            </ul>
            <TbSearch color="black" onClick={()=>setShowSearch(true)}/>
           {<span className="cart-icon d-block d-lg-none" style={{marginRight:"10px"}} onClick={()=> setShowCart(true)}>
                <FourDotMenu/>
                {!!cartCount && <span>{cartCount}</span>}
            </span>}
            </div>
        </div>
    </header>
    {showCart && <Cart setShowCart={setShowCart}/>}
    {showSearch && <Search setShowSearch={setShowSearch}/>}
    </>
    );
};


export default Header;
