import React,{useState} from 'react';
import './Gallery.css'; // Import your CSS file for styling
import PictureViewerModal from './PictureViewerModal';

const Gallery = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState("");
    const [selectedImageTitle, setSelectedImageTitle] = useState("");
    const [products,setProducts]=useState(
      [
        "productsimg/1.jpg","productsimg/2.jpg","productsimg/3.jpg","productsimg/4.jpg","productsimg/5.jpg","productsimg/6.jpg"
      ]
    );
    const toggleModal = () => {
        setModalOpen(!modalOpen);
      };
    
      const openPictureModal = (imageUrl, imageTitle) => {
        setSelectedImageUrl(imageUrl);
        setSelectedImageTitle(imageTitle);
        toggleModal();
      };
  // Dummy object array for the gallery


  return (
    <section className="gallery-container">
        <br/>
      <h2>Photo Gallery</h2>
      <div className="image-grid">
        {products && products.map((image,index) => (
            image.toString().trim() == "" ? "":
          <div key={index} className="gallery-item">
            <img 
      onClick={()=>{
        openPictureModal(image,"Gallery");
    }} src={image} alt={index} />
          </div>
        ))}
      </div>


<PictureViewerModal
        isOpen={modalOpen}
        toggle={toggleModal}
        imageUrl={selectedImageUrl}
        modalTitle={selectedImageTitle}
      />
    </section>
  );
};

export default Gallery;
