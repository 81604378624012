import React from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import './Services.css'; // Import your CSS file for styling

const servicesData = [
    {
        title: "Digital Inclusion Initiatives",
        description: "Our Digital Inclusion Initiatives aim to bridge the digital divide within the community. In an increasingly connected world, access to technology is crucial for education, employment, and social engagement. Through training programs, access to affordable devices, and community Wi-Fi initiatives, we empower individuals with the digital skills and resources needed to thrive in the digital age."
      },
    {
      title: "Green Energy Advocacy",
      description: "Our Green Energy Advocacy program focuses on promoting sustainable practices and renewable energy sources within our community. Through educational workshops, outreach events, and partnerships with local businesses, we aim to raise awareness about the importance of environmental conservation and encourage the adoption of eco-friendly technologies.",
    },
    {
      title: "Health and Wellness Workshops",
      description: "In our Health and Wellness Workshops, we provide valuable information and resources to promote physical and mental well-being. Topics include nutrition, fitness, stress management, and mindfulness. We believe that a healthy community is a thriving community, and our workshops aim to empower individuals to make positive lifestyle choices.",
    },
    {
      title: "Youth Empowerment Programs",
      description: "Empowering the next generation is crucial for building a bright future. Our Youth Empowerment Programs offer mentorship, skill development, and educational opportunities for young individuals. Through partnerships with schools and community organizations, we strive to instill confidence, leadership skills, and a sense of social responsibility in our youth.",
    },
    // Add more services as needed
  ];
  

const ServicesCarousel = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === servicesData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? servicesData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = servicesData.map((service, index) => (
    <CarouselItem key={index}>
      <div className="service">
        <h3>{service.title}</h3>
        <p>{service.description}</p>
      </div>
    </CarouselItem>
  ));

  return (
    <div className="carousel-container">
        <br/><br/><br/><br/>
        <h1 className='text-center'>Our Services</h1>
        <br/>
      <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={false}>
        <CarouselIndicators items={servicesData} activeIndex={activeIndex} onClickHandler={setActiveIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </div>
  );
};

export default ServicesCarousel;
