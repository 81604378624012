import React,{useState} from 'react';
import './Contact.css'; // Import your CSS file for styling

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        // Simulate form submission logic (logging to console)
        console.log('Form submitted with data:', formData);
      };
  return (
    <section id="contact" className="contact-container">
        <br/><br/><br/><br/>
      <h2>Contact Us</h2>
      <p>
        Have questions or want to get involved? Reach out to us using the information below.
      </p>

      <div className="contact-info">
        <p>Email: gorural@madewithrural.farm</p>
        <p>Phone: 083 214 5003</p>
        <p>Unit 3, Car. N14 & R24 Road Tarlton Mogale City 1749</p>
      </div>

      <div className="contact-form">
        <h3>Send us a message</h3>
        {/* Add your contact form here */}
        {/* Example form fields: Name, Email, Message, Submit button */}
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input  className='form-control' type="text" id="name" name="name" required />

          <label htmlFor="email">Email:</label>
          <input  className='form-control' type="email" id="email" name="email" required />

          <label htmlFor="message">Message:</label>
          <textarea className='form-control' id="message" name="message" rows="4" required />

          <button type="submit">Submit</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
