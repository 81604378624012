import React from "react";
import "./Footer.scss";
import { FaLocationArrow, FaMobileAlt, FaEnvelope } from "react-icons/fa";
// import Payment from "../../assets/payments.png";
const Footer = () => {
    return <footer className="footer">
        <div className="footer-content">
            <div className="col"><div className="title">
                    Contact
                </div>
                <div className="c-item">
                    <FaLocationArrow/>
                    <div className="text">Unit 3, Car. N14 & R24 Road Tarlton Mogale City 1749</div></div>
                    <div className="c-item">
                    <FaMobileAlt/>
                    <div className="text">083 214 5003</div></div>
                    <div className="c-item">
                    <FaEnvelope/>
                    <div className="text">gorural@madewithrural.farm
                    </div>
                    </div>
                    </div>
            <div className="col">
                <div className="title">Categories</div>
                <span className="text">Fruits and Vegetables</span>
                <span className="text">Groceries</span>
                <span className="text">Seeds and Fertilizers</span>
                <span className="text">Farm Equipments</span>
                </div>
            <div className="col">
                <div className="title">Pages</div>
                <span className="text">Home</span>
                <span className="text">About</span>
                <span className="text">Blog</span>
                {/* <span className="text">Returns</span>
                <span className="text">Terms & Conditions</span>
                <span className="text">Contact us</span> */}
                </div>
        </div>
        <div className="bottom-bar">
        <div className="bottom-bar-content">
            <div className="text">
               2023 @The Rural Box Foundation. All rights reserved.
            </div>
            {/* <img src={Payment} alt="" /> */}
        </div>
        </div>
    </footer>;
};

export default Footer;