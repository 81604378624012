import { useNavigate, useParams } from "react-router-dom";
import "./blogpost.scss";
import "./utils.scss";
import "./style.scss";
import "./mobile.scss";
import IMG1 from "./img1.png";
import IMG2 from "./img2.png";
import IMG3 from "./img3.png";
import IMG4 from "./img4.png";
import IMG5 from "./img5.png";
import IMG6 from "./img6.png";

const BlogPost = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const articles = [
    {
      id: 1,
      title: "Vertical Farming: The Future of Sustainable Agriculture.",
      author: "Thabang Moatshe",
      date: "07 November 2023",
      readTime: "8 min read",
      image: IMG1,
      slug: "/blogpost1",
      description: "Explore the innovative world of vertical farming and its potential to revolutionize sustainable agriculture. Learn how vertical farms stack layers of crops, optimize resources, and contribute to efficient, year-round production in a controlled environment."
 
    },
    {
      id: 2,
      title: "Bamboo Farming: The Sustainable Crop of the Future.",
      author: "George Sindane",
      date: "15 October 2023",
      readTime: "6 min read",
      image: IMG2,
      slug: "/blogpost2",
      description: "Discover the potential of bamboo farming and its role as a sustainable crop for the future. Explore the environmental benefits, versatile applications, and the impact of bamboo cultivation on promoting sustainability in agriculture."
    },
    {
      id: 3,
      title: "Diversifying Farming Business: The Power of Side Hustles.",
      author: "Victor Sepeng",
      date: "19 July 2023",
      readTime: "7 min read",
      image: IMG3,
      slug: "/blogpost3",
      description: "Explore the dynamic world of farming and discover the power of side hustles in agriculture. Learn how diversifying your farming business can lead to new opportunities, increased resilience, and a thriving agricultural enterprise."
    },
    {
      id: 4,
      title: "Sweet Success: The Art and Science of Apiculture.",
      author: "Kegomoditswe",
      date: "22 September 2023",
      readTime: "8 min read",
      image: IMG4,
      slug: "/blogpost4",
      description: "Dive into the world of apiculture and discover the sweet success that comes with the art and science of beekeeping. Uncover the fascinating journey of nurturing bees, harvesting honey, and the vital role bees play in our ecosystem."
    },
    {
      id: 5,
      title: "Animal Husbandry: Nurturing the Bonds Between Humans and Animals.",
      author: "Anonymous",
      date: "28 November 2022",
      readTime: "7 min read",
      image: IMG5,
      slug: "/blogpost5",
      description: "Explore the timeless practice of animal husbandry, where the connections between humans and animals flourish. Learn about the nurturing bond, the challenges, and the rewards that come with this essential aspect of agriculture."
    },
    {
      id: 6,
      title: "From Farm to Future: The Rise of Agrotech.",
      author: "Victor Hudson",
      date: "2 May 2022",
      readTime: "6 min read",
      image: IMG6,
      slug: "/blogpost6",
      description: "Embark on a journey from traditional farming to the cutting edge of agrotech. Discover how technology is shaping the future of agriculture, revolutionizing farming practices, and contributing to sustainable food production."
    },
  ];

  const currentArticle = articles.filter((article) => article.id === Number(id))[0];

  
return (
<>
<center><img src={currentArticle.image} style={{maxWidth:"100%",minHeight:"300px"}} className="vertical"/></center>
<div className="m-auto blog-post-content max-width-2 m-auto my-2">
  <h1 className="font1">{currentArticle.title}</h1>
  <div className="blogpost-meta">
    <div className="author-info">
      <div>
        <b>{currentArticle.author}</b>
      </div>
      <pre><div>{currentArticle.date+currentArticle.readTime}</div></pre>
    </div>
  </div>
  <p className="font1">
   {currentArticle.description}
</p>
</div>
<div className="max-width-1 m-auto">
  <hr />
</div>
</>
);
};

export default BlogPost;
