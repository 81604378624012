import { MdClose }  from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./Cart.scss";


const Cart = ({setShowCart}) => {
    
    const navigate = useNavigate();
    return (
        <div className="cart-panel">
          <div className="opac-layer"></div>
          <div className="cart-content">
            <div className="cart-header">
                <span className="heading">Menu</span>
                <span className="close-btn" onClick={()=>setShowCart(false)}>
                    < MdClose />
                    <span className="text">Close</span>
                </span>
            </div>
            <ul>
            <li className="nav-link text-dark pd" onClick={() => {navigate("/");setShowCart(false)}}>Home</li>
            <li className="nav-link text-dark pd" onClick={() => {navigate("/about");setShowCart(false)}}>About</li>
            <li className="nav-link text-dark pd" onClick={() => {navigate("/services");setShowCart(false)}}>Services</li>
            <li className="nav-link text-dark pd" onClick={() => {navigate("/gallery");setShowCart(false)}}>Gallery</li>
            <li className="nav-link text-dark pd" onClick={() => {navigate("/blog");setShowCart(false)}}>Blogs</li>
            <li className="nav-link text-dark pd" onClick={() => {navigate("/contact");setShowCart(false)}}>Contact</li>
            </ul>
          </div>
        </div>
        );
};

export default Cart;
