import { useState } from "react";
import PictureViewerModal from "../../Home/PictureViewerModal";

const Product = ({id, data}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState("");
    const [selectedImageTitle, setSelectedImageTitle] = useState("");

    const toggleModal = () => {
        setModalOpen(!modalOpen);
      };
    
      const openPictureModal = (imageUrl, imageTitle) => {
        setSelectedImageUrl(imageUrl);
        setSelectedImageTitle(imageTitle);
        toggleModal();
      };
    return (<>
    <div onClick={()=>{
        openPictureModal(data,"Gallery");
    }} style={{width:"300px",height:"300px",borderRadius:"15px",margin:"10px",display:"inline-block",backgroundImage:`url('${data}')`,backgroundSize:"cover",float:"left"}}></div>    
    <PictureViewerModal
        isOpen={modalOpen}
        toggle={toggleModal}
        imageUrl={selectedImageUrl}
        modalTitle={selectedImageTitle}
      />
    </>
    );
};

export default Product;
