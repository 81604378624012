import { useEffect } from "react";
import "./Products.scss";
import Product from "./Product/Product";

const Products = ({ products, innerPage, headingText }) => {
  useEffect(() => {
    if (products.length > 0) {
      localStorage.setItem("products", JSON.stringify(products));
    }
  }, [products]);

  const _products = JSON.parse(localStorage.getItem("products"));

  // Check if _products array is empty, return null if true
  if (!_products || _products.length === 0) {
    return null;
  }

  return (
    <div style={{marginTop:"25px"}} className="products-container">
      <h1 className="text-center">Gallery</h1><br/><br/>
      <table className="products"  style={{maxWidth:"100vw",overflowX:"scroll"}}>
        <tbody><tr>{_products.map((item, index) => (
          item.toString().trim() !== "" ? <td><Product key={index} id={index} data={item} /></td>:""
        ))}</tr></tbody>
      </table>
    </div>
  );
};

export default Products;
