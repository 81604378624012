import { HashRouter, Routes, Route } from "react-router-dom";
// TO MAINTAIN A LINK BETWEEN MULTIPLE PAGES OF THE WEBSITE.
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Category from "./components/Category/Category";
import SingleProduct from "./components/SingleProduct/SingleProduct";
import NewsLetter from "./components/Footer/Newsletter/Newsletter";
import AppContext from "./utils/context";
import Blog from "./components/Header/Blog/Blog";
import BlogPost from "./components/Header/Blog/blogpost1";
import About from "./components/Home/AboutPage";
import ServicesCarousel from "./components/Home/ServicesPage";
import Contact from "./components/Home/Contact";
import Gallery from "./components/Home/Gallery";

function App() {
    return (
        <HashRouter>
            <AppContext>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/category/:id" element={<Category />} />
                    <Route path="/product/:id" element={<SingleProduct />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blogpost/:id" element={<BlogPost />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<ServicesCarousel />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/gallery" element={<Gallery />} />
                </Routes>
                <Footer />
            </AppContext>
        </HashRouter>
    );
}

export default App;
