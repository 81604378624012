import React from 'react';
import TruncatedText from './TruncateText';
import childrenpic from '../../assets/childrenfood.jpg';
const About = () => {
    
  return (
    <div>
      <br/><br/><br/><br/>
      <div className='justify-content-center' style={{padding:"10px"}}>
        <br/>
      <h2 className='text-center'>About Our Non-Profit Organization</h2>
      <br/>
        <div className='container'><div className='row'><div className='col-lg-6' style={{padding:"15px"}}>
          <img style={{maxWidth:"100%",borderRadius:"10px"}} src={childrenpic}/>
        </div>
        <div className='col-lg-6' style={{padding:"10px",display:"flex",alignItems:"center",justifyContent:"left"}}>
          <div><p>
        Welcome to our non-profit organization dedicated to sustainable farming
        and community development.
      </p>
      <p> At the heart of our mission is the belief
        that responsible farming practices can contribute to the well-being of
        both the environment and the community.
      </p>

      <p> At the heart of our mission is the belief
        that responsible farming practices can contribute to the well-being of
        both the environment and the community.
      </p>

      <p> At the heart of our mission is the belief
        that responsible farming practices can contribute to the well-being of
        both the environment and the community.
      </p>
      </div>
      </div>
        </div>
        </div>
       
      <br/>
      <div className='container'>
        <div className='row'>
            <div className='col-lg-4'>
                <div className='card' style={{padding:"10px",minHeight:"215px",margin:"10px"}}>
      <h3>Our Mission</h3>
     
      {TruncatedText({ maxLength: 200, text: "Our mission is to promote sustainable agriculture, empower local farmers, and foster community growth. We strive to create a positive impact by providing resources, education, and support to those dedicated to cultivating a healthier and more sustainable future."})}
  
      </div>
      </div>
      <div className='col-lg-4'>
        <div className='card' style={{padding:"10px",minHeight:"215px",margin:"10px"}}>
      <h3>What We Do</h3>
      {TruncatedText({maxLength:200,text:`
        - Sustainable Farming Practices: We advocate for and implement
        sustainable and eco-friendly farming techniques to preserve natural
        resources and promote biodiversity.
        _galattica_moodler
        - Community Contributions: We actively engage with and contribute to
        the local community by organizing events, workshops, and initiatives
        that promote agricultural education, food security, and overall
        well-being.
        _galattica_moodler
        - Collaboration and Support: We collaborate with local farmers, schools,
        businesses, and community organizations to create a network of support
        that fosters growth, learning, and positive change.
      `
      })}
      </div>
      </div>
      <div className='col-lg-4'>
        <div className='card' style={{padding:"10px",minHeight:"215px",margin:"10px"}}>
      <h3>Get Involved</h3>
      {TruncatedText({maxLength:200,text:`_galattica_moodler
        If you share our passion for sustainable farming and community
        development, we invite you to join us in making a difference. Whether
        you're a farmer, educator, student, or community member, there are
        various ways to get involved and contribute to our shared goals.
        galattica_moodler
        Contact us to learn more about volunteering, upcoming events, or
        collaboration opportunities. Together, we can cultivate a better future
        for everyone.
        galattica_moodler`})}
      </div>
      </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default About;
