import "./Banner.scss";
import bg from "../../../assets/bannerbg.jpg";

const Banner = () => {
  return (
    <div className="hero-banner text-center" style={{backgroundImage:`url(${bg})`}}>
      <div className="content text-center" >
        <div className="text-content text-center" style={{width:"100%"}}>
          <h1>MAKE A DIFFERENCE</h1>
          <p>Adding Kindness to your Life.</p>
          <p>Bringing hope and support to those in need.</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
