import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const TruncatedText = ({ maxLength, text }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  // Function to split the text into paragraphs based on the separator
  const splitTextIntoParagraphs = (text) => {
    return text.split('_galattica_moodler').map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
  };

  // Render the component
  return (
    <div>
      {/* Render paragraphs based on the separator */}
      {isTruncated
        ? splitTextIntoParagraphs(text.slice(0, maxLength))
        : splitTextIntoParagraphs(text)}

      {/* Render 'Read More'/'Read Less' button if necessary */}
      {text.length > maxLength && (
        <div>
          <font style={{color:"blue",cursor:"pointer"}} onClick={toggleTruncate}>
            {isTruncated ? '... Read More' : 'Read Less'}
          </font>
          
        </div>
      )}
    </div>
  );
};

export default TruncatedText;
