import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CSSTransition } from "react-transition-group";
import "./PictureViewerModal.scss"; // Create a separate SCSS file for styling

const PictureViewerModal = ({ isOpen, toggle, imageUrl, modalTitle }) => {
  return (
    <CSSTransition in={isOpen} timeout={400} classNames="modal-fade" unmountOnExit>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          <img
            src={imageUrl}
            alt={modalTitle}
            style={{ width: "100%", maxHeight: "70vh", objectFit: "contain" }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </CSSTransition>
  );
};

export default PictureViewerModal;
