import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText } from "reactstrap";
import "./utils.scss";
import "./mobile.scss";
import "./style.scss";
import "./Blog.scss";
import IMG1 from "./img1.png";
import IMG2 from "./img2.png";
import IMG3 from "./img3.png";
import IMG4 from "./img4.png";
import IMG5 from "./img5.png";
import IMG6 from "./img6.png";

const articles = [
  {
    id: 1,
    title: "Vertical Farming: The Future of Sustainable Agriculture.",
    author: "Thabang Moatshe",
    date: "07 November 2023",
    readTime: "8 min read",
    image: IMG1,
    slug: "/blogpost1",
    description:"wegfhrgwe wejhrtgwejhtk weqjktkjt weqjtkejkt wetq wqtwetkj tqjkhwkjre tewrjkthwqekt rewqkjhkjtq rewkjwyher wyjhy tyjlkrtjlyrt yjrty yrejh reyklt ykljekltlkerjy rtyjrtkyey tlkyjerykltryjel rteyjlktreyjl rtlkyjjt "
  
  },
  {
    id: 2,
    title: "Bamboo Farming: The Sustainable Crop of the Future.",
    author: "George Sindane",
    date: "15 October 2023",
    readTime: "6 min read",
    image: IMG2,
    slug: "/blogpost2",
    description:"wegfhrgwe wejhrtgwejhtk weqjktkjt weqjtkejkt wetq wqtwetkj tqjkhwkjre tewrjkthwqekt rewqkjhkjtq rewkjwyher wyjhy tyjlkrtjlyrt yjrty yrejh reyklt ykljekltlkerjy rtyjrtkyey tlkyjerykltryjel rteyjlktreyjl rtlkyjjt "
  
  },
  {
    id: 3,
    title: "Diversifying Farming Business: The Power of Side Hustles.",
    author: "Victor Sepeng",
    date: "19 July 2023",
    readTime: "7 min read",
    image: IMG3,
    slug: "/blogpost3",
    description:"wegfhrgwe wejhrtgwejhtk weqjktkjt weqjtkejkt wetq wqtwetkj tqjkhwkjre tewrjkthwqekt rewqkjhkjtq rewkjwyher wyjhy tyjlkrtjlyrt yjrty yrejh reyklt ykljekltlkerjy rtyjrtkyey tlkyjerykltryjel rteyjlktreyjl rtlkyjjt "
  
  },
  {
    id: 4,
    title: "Sweet Success: The Art and Science of Apiculture.",
    author: "Kegomoditswe",
    date: "22 September 2023",
    readTime: "8 min read",
    image: IMG4,
    slug: "/blogpost4",
    description:"wegfhrgwe wejhrtgwejhtk weqjktkjt weqjtkejkt wetq wqtwetkj tqjkhwkjre tewrjkthwqekt rewqkjhkjtq rewkjwyher wyjhy tyjlkrtjlyrt yjrty yrejh reyklt ykljekltlkerjy rtyjrtkyey tlkyjerykltryjel rteyjlktreyjl rtlkyjjt "
  
  },
  {
    id: 5,
    title: "Animal Husbandry: Nurturing the Bonds Between Humans and Animals.",
    author: "Anonymous",
    date: "28 November 2022",
    readTime: "7 min read",
    image: IMG5,
    slug: "/blogpost5",
    description:"wegfhrgwe wejhrtgwejhtk weqjktkjt weqjtkejkt wetq wqtwetkj tqjkhwkjre tewrjkthwqekt rewqkjhkjtq rewkjwyher wyjhy tyjlkrtjlyrt yjrty yrejh reyklt ykljekltlkerjy rtyjrtkyey tlkyjerykltryjel rteyjlktreyjl rtlkyjjt "
  
  },
  {
    id: 6,
    title: "From Farm to Future: The Rise of Agrotech.",
    author: "Victor Hudson",
    date: "2 May 2022",
    readTime: "6 min read",
    image: IMG6,
    slug: "/blogpost6",
    description:"wegfhrgwe wejhrtgwejhtk weqjktkjt weqjtkejkt wetq wqtwetkj tqjkhwkjre tewrjkthwqekt rewqkjhkjtq rewkjwyher wyjhy tyjlkrtjlyrt yjrty yrejh reyklt ykljekltlkerjy rtyjrtkyey tlkyjerykltryjel rteyjlktreyjl rtlkyjjt "
  },
];

const Blog = () => {
  const navigate = useNavigate();
  function limitCharacters(text, limit) {
    if (text.length <= limit) {
      return text;
    } else {
      return text.slice(0, limit) + '...';
    }
  }
  
  return (
    <>
      <div className="blog-banner" style={{height:"400px"}}>
        <div className="content">
          <div className="text-content text-center" style={{width:"100%"}}>
            <h1 className="text-center blog-banner-title">The Rural Box Foundation Blogs</h1>
            <p className="text-center">
              Join the Conversation, where Ideas Come to Life. <br />
              Creating Connections Through Words.
            </p>
          </div>
        </div>
      </div>

      <div className="home-articles max-width-1 m-auto font2">
        <h2>Featured Articles</h2>
      <div className="container">
        <div className="row">
        {articles.map((article) => (
          <div className="col-lg-4" style={{padding:"10px"}}>
       <Card style={{ cursor: "pointer" }} key={article.id} onClick={() => navigate(`/blogpost/${article.id}`)}>
       <CardImg top width="100%" src={article.image} alt={article.title} />
            <CardBody>
              <CardTitle tag="h3">{limitCharacters(article.title,50)}</CardTitle>
              <CardSubtitle>{article.author}</CardSubtitle>
              <CardText>
                {`${article.date} | ${article.readTime}`}
              </CardText>
            </CardBody>
          </Card>
          </div>
        ))}
        
        </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
